<template>
  <NewsCard
    description="Klik op de button om een nieuwsitem toe te voegen aan de App."
    featured
    :image="require('@/assets/img/events/meeting.webp')"
    name="Nieuwsitem toevoegen"
  />

  <NewsCard
    v-for="item in newsItems"
    :key="item.id"
    :description="item.description"
    :news-item-id="item.id"
    :image="item.visualImage"
    :name="item.title"
    :publish-date-time="item.publishDate"
    :reaction-count="item.reactionCount"
  />
</template>

<script>
import { getAll } from '@/api/providers/news';
import NewsCard from '@/components/News/Card.vue';

export default {
  name: 'NewsIndex',

  components: {
    NewsCard,
  },

  data: () => ({
    newsItems: [],
  }),

  mounted() {
    this.loadNewsItems();
  },

  methods: {
    loadNewsItems() {
      getAll()
        .then((newsItems) => {
          this.newsItems = newsItems
        })
        .catch((error) => { if(error.default !== undefined) error.default() });
    },
  },
};
</script>
